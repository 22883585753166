import type { Entity } from "./entity";
import type { LangCoded } from "./coded";

export const enum ReportCategory {
  CHAPTER = "chapter",
  GROUP = "organization",
  MANGA = "title",
  USER = "user",
}

export const enum ReportStatus {
  WAITING = "waiting",
  ACCEPTED = "accepted",
  REFUSED = "refused",
  AUTORESOLVED = "autoresolved",
}

export type ReportApprovalDecision =
  | "undecided"
  | "accepted"
  | "rejected"
  | "auto_approved";
export type ReportAction = "approve" | "auto_approve" | "reject" | "reset";

export interface ReportReasonAttributes {
  reason: LangCoded;
  detailsRequired: boolean;
  category: ReportCategory;
}

export interface ReportAttributes {
  details: string;
  objectId: string;
  status: ReportStatus;
  createdAt: string;
}

export interface ReportPostBody {
  entityType:
    | "title"
    | "chapter"
    | "organization"
    | "user"
    | "cover_art"
    | "comment"
    | "post"
    | "conversation";
  entityId: string;
  message: string;
}

export type ReportReason = Entity<"report_reason", ReportReasonAttributes>;
export type ReportEntity = Entity<"report", ReportAttributes>;
